import React, { Fragment, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/css';

const PC = ({ children }: { children: ReactNode }) => {
  const isPc = useMediaQuery({
    query: '(min-width: 1000px)'
  });
  return <Fragment>{isPc && children}</Fragment>;
};

const Mobile = ({ children }: { children: ReactNode }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 999px)'
  });
  return <div className={css({ position: 'relative' })}>{isMobile && children}</div>;
};

export { PC, Mobile };
