import React from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { BuddystockLogoIcon, BuddystockTitleIcon } from '../../static/images';
import { OpenMail } from '../OpenMail';

const navStyle = css`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 30;
  border-bottom: 1px solid #ebebeb;
`;

const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 952px;
  margin: 0 auto;
  padding: 30px 0px;
`;

const logoStyle = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const textButtonStyle = css`
  grid-column: 2;
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
`;

const youtuberStyle = css`
  cursor: pointer;
  margin-right: 24px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const downloadStyle = css`
  cursor: pointer;
  padding: 10px 30px;
  background: #19dcae;
  color: #3d3d3d;
  border-radius: 8px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const PcVerNav = () => (
  <div className={navStyle}>
    <div className={containerStyle}>
      <div className={logoStyle}>
        <img src={BuddystockLogoIcon} width={47} height={29} alt={'buddystock_logo'} />
        <img
          src={BuddystockTitleIcon}
          width={163}
          height={34}
          alt={'buddystock_title'}
          className={css({ marginLeft: 12 })}
        />
      </div>
      <div className={textButtonStyle}>
        <span className={youtuberStyle}>
          <OpenMail value={'유튜버 제휴 문의'} isHeader={true} />
        </span>
        <div
          className={downloadStyle}
          onClick={() =>
            window.open('https://play.google.com/store/apps/details?id=com.pickstudio.buddystock', ' _blank')
          }
        >
          앱 다운로드
        </div>
      </div>
    </div>
  </div>
);

export default PcVerNav;
