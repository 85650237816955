import React from 'react';
import '../../../index.css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { bottomImageStyle, topImageStyle } from '../../../style/style';
import { MobileSecondBottomImg, MobileSecondTomImg } from '../../../static/images';

const MobileVerSectionSecond = () => (
  <MobileDefaultTemplate
    text={'친구들과 나누는 실시간 매매 정보'}
    description={'닉네임은 물론, 연락처로도 친구를 찾을 수 있어요. 친구들의 매매 정보를 실시간으로 받아보세요.'}
    desWidth={280}
    imgContainerStyle={{
      width: '238px',
      margin: '26px auto 52px',
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <div className={topImageStyle({ top: '0%' })}>
      <img src={MobileSecondTomImg} alt={'buddystock_top_img'} />
    </div>
    <div className={bottomImageStyle({ top: '5%', left: '13%' })}>
      <img src={MobileSecondBottomImg} alt={'buddystock_bottom_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionSecond;
