import { css } from '@emotion/css';

export const rootStyle = (bgColor: string) => css`
  background-color: ${bgColor};
  width: 100%;
`;

export const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 952px;
  margin: 0 auto;
  justify-content: center;
`;

export const contentStyle = (other: any) => css`
  ${other};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const mainTextStyle = (other: any) => css`
  ${other};
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.2px;
  word-break: keep-all;
`;

export const descriptionTextStyle = (other: any) => css`
  ${other};
  margin-top: 24px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #3d3d3d;
  word-break: keep-all;
`;

export const imageBoxStyle = (other: any) => css`
  position: relative;
  height: 520px;
  ${other};
`;

export const topImageStyle = (other: any) => css`
  position: absolute;
  z-index: 2;
  ${other};
`;

export const middleImageStyle = (other: any) => css`
  position: absolute;
  z-index: 1;
  ${other};
`;

export const bottomImageStyle = (other: any) => css`
  position: absolute;
  ${other};
`;
