import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { mainTextStyle } from '../../../style/style';
import { PcFifthTopImg } from '../../../static/images';

const boxStyle = css`
  display: grid;
  justify-content: center;
  padding-top: 120px;
`;

const imgDivStyle = css`
  display: grid;
  justify-content: center;
  margin-top: 144px;
  height: 552px;
  overflow: hidden;
`;

const customTextStyle = {
  display: 'flex',
  textAlign: 'center',
  fontSize: '40px',
  width: '476px'
};

const PcVerSectionFifth = () => (
  <div className={boxStyle}>
    <div className={mainTextStyle(customTextStyle)}>내 주위 숨은 고수들과 나누는 실시간 주식・코인 소통의 장</div>
    <div className={imgDivStyle}>
      <img src={PcFifthTopImg} alt={'buddystock_fifth_top'} />
    </div>
  </div>
);

export default PcVerSectionFifth;
