import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import {
  BuddystockGooglePlayIcon,
  BuddystockAppleStoreIcon,
  PcFirstTopImg,
  PcFirstBottomImg
} from '../../../static/images';
import {
  bottomImageStyle,
  containerStyle,
  contentStyle,
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  rootStyle,
  topImageStyle
} from '../../../style/style';
import IconButton from '../../IconButton';
import { APP_STORE } from '../../../constants';

const buttonDivStyle = css`
  display: flex;
  justify-content: start;
`;

const PcVerSectionFirst = () => (
  <div className={rootStyle('#2AF5C6')}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '160px 0px' })}>
        <div className={css({ width: '420px', marginTop: '40px' })}>
          <div className={mainTextStyle({ fontSize: '50px' })}>
            세상에 없던
            <br />
            지인 기반 금융 SNS, 버디스탁
          </div>
          <div className={descriptionTextStyle({ marginBottom: '80px' })}>
            주식과 코인으로 재테크 하는 세상, <br />
            가까운 내 지인들은 무엇을 거래하고 있을까요? <br />
            버디스탁은 소통하는 금융 문화를 지향합니다.
          </div>
          <div className={buttonDivStyle}>
            <IconButton
              label={'Google Play'}
              icon={<BuddystockGooglePlayIcon />}
              color={'black'}
              width={200}
              onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
            />
            <IconButton
              label={'Apple Store'}
              icon={
                <BuddystockAppleStoreIcon
                  className={css({
                    '& > path': {
                      fill: '#ffffff'
                    }
                  })}
                />
              }
              color={'black'}
              width={200}
              onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
            />
          </div>
        </div>
        <div className={imageBoxStyle({ width: '420px' })}>
          <div className={topImageStyle({ top: '-5%', right: '-7%' })}>
            <img src={PcFirstTopImg} alt={'buddystock_top_img'} />
          </div>
          <div className={bottomImageStyle({ top: '0%', left: '-7%' })}>
            <img src={PcFirstBottomImg} alt={'buddystock_bottom_img'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionFirst;
