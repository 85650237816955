import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon, BuddystockSixthIcon } from '../../../static/images';
import { mainTextStyle } from '../../../style/style';
import IconButton from '../../IconButton';
import { APP_STORE } from '../../../constants';

const rootStyle = css`
  width: 100%;
  min-width: 320px;
  display: grid;
  justify-content: center;
  padding: 104px 0px 83px;
  background-color: #3d3d3d;
`;

const centerContainerStyle = css`
  display: grid;
  justify-content: center;
`;

const customTextStyle = {
  display: 'grid',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '24px',
  color: '#ffffff',
  margin: '16px 0px 64px',
  width: '226px'
};

const MobileVerSectionSixth = () => (
  <div className={rootStyle}>
    <div className={centerContainerStyle}>
      <img
        src={BuddystockSixthIcon}
        alt={'buddystock_sixth_top'}
        width={150}
        height={150}
        style={{ marginTop: '-12px' }}
      />
    </div>
    <div className={centerContainerStyle}>
      <div className={mainTextStyle(customTextStyle)}>
        <span>
          지금 바로 <br />
          버디스탁을 만나보세요
        </span>
      </div>
    </div>
    <IconButton
      isMobile
      label={'Google Play'}
      icon={<BuddystockGooglePlayIcon />}
      color={'white'}
      width={312}
      onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
    />
    <IconButton
      isMobile
      label={'Apple Store'}
      icon={<BuddystockAppleStoreIcon />}
      color={'white'}
      width={312}
      onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
    />
  </div>
);

export default MobileVerSectionSixth;
