import React, { ReactNode } from 'react';
import { css } from '@emotion/css';
import { mainTextStyle } from '../../style/style';

export const rootStyle = (bgColor: string) => css`
  background-color: ${bgColor};
  width: 100%;
  min-width: 320px;
  display: grid;
  justify-content: center;
  padding-top: 40px;
`;

const centerContainer = css`
  display: flex;
  justify-content: center;
`;

const customMainTextStyle = {
  textAlign: 'center',
  fontSize: '24px',
  width: '220px',
  lineHeight: '34px'
};

const descriptionStyle = (desWidth: number) => css`
  width: ${desWidth || 262}px;
  text-align: center;
  margin-top: 24px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #3d3d3d;
  word-break: keep-all;
`;

const imageContainerStyle = (other: any) => css`
  position: relative;
  height: 320px;
  ${other};
`;

const MobileDefaultTemplate = ({
  children,
  backgroundColor,
  text,
  textStyle,
  description,
  desWidth = 0,
  imgContainerStyle
}: {
  children: ReactNode;
  text: string;
  backgroundColor?: string;
  description?: string | ReactNode;
  desWidth?: number;
  textStyle?: any;
  imgContainerStyle?: any;
}) => (
  <div className={rootStyle(backgroundColor || '')}>
    <div className={centerContainer}>
      <span className={mainTextStyle(textStyle || customMainTextStyle)}>{text}</span>
    </div>

    {description && (
      <div className={centerContainer}>
        <div className={descriptionStyle(desWidth)}>{description}</div>
      </div>
    )}
    {imgContainerStyle && <div className={imageContainerStyle(imgContainerStyle)}>{children}</div>}
    {!imgContainerStyle && children}
  </div>
);

export default MobileDefaultTemplate;
