import React from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { OpenMail } from '../OpenMail';
import { BuddystockLogoIcon, BuddystockTitleIcon } from '../../static/images';

const navStyle = css`
  position: sticky;
  top: 0;
  width: 100%;
  min-width: 320px;
  background-color: #ffffff;
  z-index: 30;
  border-bottom: 1px solid #ebebeb;
`;

const containerStyle = css`
  display: flex;
  width: 92%;
  margin: 0 auto;
  padding: 16px 0px;
`;

const logoStyle = css`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
`;

const youtuberStyle = css`
  display: flex;
  justify-content: end;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const MobileVerHeaderNav = () => (
  <div className={navStyle}>
    <div className={containerStyle}>
      <div className={logoStyle}>
        <img src={BuddystockLogoIcon} width={23} height={13} alt={'buddystock_logo'} />
        <img
          src={BuddystockTitleIcon}
          width={80}
          height={16}
          alt={'buddystock_title'}
          className={css({ marginLeft: 6 })}
        />
      </div>
      <div className={youtuberStyle}>
        <OpenMail value={'유튜버 제휴 문의'} isHeader={true} />
      </div>
    </div>
  </div>
);

export default MobileVerHeaderNav;
