import React, { ReactNode } from 'react';
import { OpenMail } from '../OpenMail';
import { css } from '@emotion/css';

const termsTextStyle = css`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #c1c1c1;
`;

interface IItem {
  label: ReactNode;
  event?: () => void;
}

export const INQUIRY_ITEMS: IItem[] = [
  {
    label: '앱 다운로드',
    event: () => window.open('https://play.google.com/store/apps/details?id=com.pickstudio.buddystock', ' _blank')
  },
  {
    label: <OpenMail value={'고객 문의'} />
  },
  {
    label: <OpenMail value={'유튜버 제휴 문의'} />
  },
  {
    label: <OpenMail value={'팀 합류 문의'} />
  }
];

export const TERM_ITEMS: IItem[] = [
  {
    label: (
      <a href={'/terms'} target="_blank" className={termsTextStyle} rel="noreferrer">
        서비스 이용약관
      </a>
    )
  },
  {
    label: (
      <a href={'/policy'} target="_blank" className={termsTextStyle} rel="noreferrer">
        개인정보 처리방침
      </a>
    )
  }
];
