import React from 'react';
import '../../../index.css';
import { bottomImageStyle, topImageStyle } from '../../../style/style';
import { MobileFourthBottomImg, MobileFourthTopImg } from '../../../static/images';
import MobileDefaultTemplate from '../MobileDefaultTemplate';

const MobileVerSectionFourth = () => (
  <MobileDefaultTemplate
    backgroundColor={'#B8FFFB'}
    text={'오직 내 친구에게만 보여주는 매매 내역'}
    description={'걱정 마세요. 거래량은 공개되지 않아요! 오직 거래 종목만 버디에게 공개됩니다.'}
    desWidth={240}
    imgContainerStyle={{ width: '256px', margin: '60px 0 46px' }}
  >
    <div className={topImageStyle({})}>
      <img src={MobileFourthTopImg} alt={'buddystock_top_img'} />
    </div>
    <div className={bottomImageStyle({ top: '8%', right: '-11.5%' })}>
      <img src={MobileFourthBottomImg} alt={'buddystock_bottom_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFourth;
