import React, { ReactNode } from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { topImageStyle } from '../../../style/style';
import { BuddystockChartIcon, BuddystockUpIcon, MobileThirdTopImg } from '../../../static/images';

const containerStyle = css`
  display: flex;
  width: 308px;
  padding: 44px 0 119px;
  justify-content: space-between;
`;

const imageContainerStyle = css`
  position: relative;
  width: 132px;
  height: 320px;
  word-break: keep-all;
`;

const iconContainerStyle = css`
  margin-top: 20px;
`;

const iconTitleStyle = css`
  margin: 3px 0px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3d3d3d;
`;

const iconContentStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #8b8b8b;
`;

const IconComponent = ({
  icon,
  title,
  content,
  isUpIcon
}: {
  icon: string;
  title: string;
  content: ReactNode;
  isUpIcon: boolean;
}) => (
  <div
    className={css({
      display: 'grid',
      width: '149px',
      marginBottom: isUpIcon ? '44px' : '0px'
    })}
  >
    <div>
      <img src={icon} alt={'buddystock_icon'} width={20} height={20} />
    </div>
    <div className={iconTitleStyle}>{title}</div>
    <div className={iconContentStyle}>{content}</div>
  </div>
);

const MobileVerSectionThird = () => (
  <MobileDefaultTemplate
    backgroundColor={'#F5F5F5'}
    text={'유튜버의 매매 정보를 실시간으로'}
    description={'내가 구독한 유튜버는 지금 뭘 샀을까? 버디스탁을 통해 실시간으로 받아보세요.'}
  >
    <div className={containerStyle}>
      <div className={imageContainerStyle}>
        <div className={topImageStyle({ top: '-1%', left: '-3%' })}>
          <img src={MobileThirdTopImg} alt={'buddystock_top_img'} />
        </div>
      </div>
      <div className={iconContainerStyle}>
        <IconComponent
          icon={BuddystockUpIcon}
          title={'추천 유튜버디'}
          content={
            <span>
              버디스탁에서 제공하는 <br />
              추천 유튜버를 만나보세요
            </span>
          }
          isUpIcon={true}
        />
        <IconComponent
          icon={BuddystockChartIcon}
          title={'유튜버디 매매정보'}
          content={'유튜버디의 실시간 매매 정보를 버디스탁을 통해 확인해보세요'}
          isUpIcon={false}
        />
      </div>
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionThird;
