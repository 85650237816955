import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { mainTextStyle } from '../../../style/style';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon, BuddystockSixthIcon } from '../../../static/images';
import { APP_STORE } from '../../../constants';

import IconButton from '../../IconButton';

const boxStyle = css`
  display: grid;
  justify-content: center;
  padding: 212px 0px 232px;
  background-color: #3d3d3d;
`;

const imgDivStyle = css`
  display: grid;
  justify-content: center;
`;

const customTextStyle = {
  display: 'flex',
  justifyContent: 'center',
  fontSize: '40px',
  color: '#ffffff',
  margin: '8px 0px 118px'
};

const buttonDivStyle = css`
  display: flex;
  justify-content: center;
`;

const PcVerSectionSixth = () => (
  <div className={boxStyle}>
    <div className={imgDivStyle}>
      <img src={BuddystockSixthIcon} alt={'buddystock_sixth_top'} />
    </div>
    <div className={mainTextStyle(customTextStyle)}>지금 바로 버디스탁을 만나보세요</div>
    <div className={buttonDivStyle}>
      <IconButton
        label={'Google Play'}
        icon={<BuddystockGooglePlayIcon />}
        color={'white'}
        width={240}
        onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
      />
      <IconButton
        label={'Apple Store'}
        icon={<BuddystockAppleStoreIcon />}
        color={'white'}
        width={240}
        onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
      />
    </div>
  </div>
);

export default PcVerSectionSixth;
