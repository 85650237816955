import React, { ReactNode } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { INQUIRY_ITEMS, TERM_ITEMS } from './constants';

const rootStyle = css`
  display: grid;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  padding: 44px 0px;
  background-color: #242424;
`;

const containerStyle = css`
  width: 92%;
  margin: 0 auto;
`;

const inquiryStyle = css`
  list-style: none;
  padding-inline-start: 0px;
  justify-content: start;
  align-items: center;
  & > #inquiryItem {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 112px;
    }
  }
`;

const termsStyle = css`
  list-style: none;
  padding-inline-start: 0px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const footerItemStyle = css`
  cursor: pointer;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.2px;
  &:not(:last-of-type) {
    margin-right: 32px;
  }
  color: #dedede;
`;

const copyrightTextStyle = css`
  margin-top: 12px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #666666;
`;

interface IItem {
  label: ReactNode;
  event?: () => void;
}

const FooterItemComponent = ({ items }: { items: IItem[] }) => {
  return (
    <>
      {items.map((item: IItem, index: number) => {
        return (
          <li key={index} id={'inquiryItem'} className={footerItemStyle} onClick={item.event}>
            {item.label}
          </li>
        );
      })}
    </>
  );
};

const MobileVerFooter = () => (
  <div className={rootStyle}>
    <div className={containerStyle}>
      <div className={css({ width: '275px' })}>
        <ul className={inquiryStyle}>
          <FooterItemComponent items={INQUIRY_ITEMS} />
        </ul>
        <ul className={termsStyle}>
          <FooterItemComponent items={TERM_ITEMS} />
        </ul>
      </div>
    </div>
    <div className={containerStyle}>
      <div className={copyrightTextStyle}>ⓒ Pickstudio. All rights reserved.</div>
    </div>
  </div>
);

export default MobileVerFooter;
