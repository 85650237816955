import React from 'react';
import { css } from '@emotion/css';

const body = css`
  padding: 15px;
  & > ul {
    list-style: none;
    padding-left: 5%;
  }
`;

const Terms = () => {
  return (
    <div className={body}>
      <h2>Busddystock 이용약관</h2>
      <br />
      <br />
      <h3>
        <b>제 1장 총칙</b>
      </h3>
      <h4>
        <b>제1조 (목적)</b>
      </h4>
      본 약관은 회원이 Pick Studio(이하 &apos;팀&apos;)에서 제공하는 Buddystock(이하 ‘서비스’)를 이용하는 것과 관련하여
      회원과 팀 간의 권리, 의무 및 책임사항, 서비스 이용에 따른 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로
      합니다.
      <br />
      <h4>제2조 (약관의 효력 및 변경)</h4>
      <p>
        ① 팀이 본 약관의 내용을 서비스의 이동통신형 단말기(이하 &apos;단말기&apos;)를 통하여 구현된 서비스 화면에
        게시하거나 기타의 방법으로 이용자에게 공시하고, 이에 동의한 이용자가 본 서비스를 이용할 때 약관은 그 효력이
        발생합니다.
      </p>
      <p>
        ② 팀은 필요하다고 인정되면 본 약관을 변경할 수 있으며, 약관을 변경할 때에는 곧바로 전 항과 같은 방법으로 변경
        7일 전에 본 약관 제19조의 방법으로 공지하거나 통지합니다. 다만, 회원의 권리를 제한하거나 회원에게 의무를
        부과하는 것 등에 관한 중요한 규정을 변경할 때는 변경 30일 전에 위 방법으로 공지하거나 통지합니다.
      </p>
      <p>
        ③ 팀이 제2항에 따라 변경된 약관을 공지하거나 통지하면서, 회원에게 약관 변경 적용일까지 거부 의사를 표시하지
        않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지하거나 통지하였음에도 회원이 명시적으로 약관 변경에
        대한 거부 의사를 표시하지 않는다면 회원이 변경된 약관에 동의한 것으로 간주합니다. 변경된 약관에 동의하지 않는
        회원은 서비스의 이용을 중단하고 이용 계약을 해지할 수 있습니다.
      </p>
      <br />
      <h4>제3조 (약관 외 준칙)</h4>
      <p>
        ① 본 약관에 밝히지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
        위치정보의 보호 및 이용 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등(이하 ‘관계 법령’) 및 팀이
        정한 본 서비스 세부이용지침 등의 규정을 따릅니다.
      </p>
      <p>
        ② 팀은 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 &apos;개별약관&apos;이라 함)을 정하여 이를 제2조
        제2항의 방법에 의하여 공지할 수 있습니다.
      </p>
      <p>
        ③ 팀은 필요하면 본 서비스 이용과 관련된 세부이용지침을 정하여 이를 제2조 제2항의 방법으로 공지할 수 있습니다.
      </p>
      <p>
        ④ 본 약관과 관련하여 팀의 정책 변경, 법령의 제,개정, 공공기관의 고시나 지침 등에 따라 팀이 홈페이지의 공지사항
        게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
      </p>
      <br />
      <h4>제4조(용어의 정의)</h4>
      <p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. </p>
      <ul>
        <li>
          1. &apos;Buddystock&apos;(이하 &apos;본 서비스&apos;)이란 회원의 이동전화, 휴대용 단말기 등 각종 유무선
          기기(이하 &apos;단말기&apos;)의 위치정보(GPS, 음파 등 이용)를 기반으로 회원이 이용할 수 있는 제반 서비스로서
          제9조에 규정된 서비스를 말합니다.
        </li>
        <li>2. &apos;회원&apos;이란 이동전화 서비스 이용자로서 본 서비스 약관에 동의한 자를 말합니다.</li>
        <li>
          3. &apos;제휴사&apos;란 팀과의 계약 하에 회원에게 본 서비스를 통하여 상품 등을 판매하거나 각종 정보 및 혜택,
          멤버십 포인트 및 스탬프, 티켓, 쿠폰 또는 상품권 등을 제공하는 회사를 말합니다.
        </li>
        <li>4. &apos;운영자&apos;란 본 서비스의 전반적인 관리와 원활한 운영을 위하여 팀이 선정한 자를 말합니다. </li>
        <li>
          5. &apos;모바일 어플리케이션&apos;이란 회원이 단말기에 내려 받아 설치함으로써 본 서비스를 단말기상에서 이용할
          수 있도록 하는 프로그램을 말합니다.
        </li>
        <li>
          6. &apos;상품 등&apos;이란 회원이 구매하거나 기타의 방법으로 적법하게 취득한 재화(쿠폰, 상품권 등 포함) 또는
          용역으로서 모든 상품을 말합니다.
        </li>
      </ul>
      <p>
        ② 본 약관에서 사용하는 용어의 정의는 전 항에서 정하는 것을 제외하고는 관계 법령과 서비스별 안내에서 정하는 바를
        따릅니다.
      </p>
      <br />
      <br />
      <h3>제2장 서비스 이용계약</h3>
      <h4>제5조 (이용 계약의 성립)</h4>
      <p>
        ① 팀은 본 서비스를 이용하고자 하는 자(이하 ‘이용자’)가 홈페이지 또는 단말기를 통하여 제시된 본 서비스 이용약관에
        &apos;동의함&apos; 버튼을 선택할 시 약관에 동의하는 것으로 간주합니다.
      </p>
      <p>② 동조 1항의 동의함 버튼을 선택하지 않을 시 팀은 본 서비스의 이용을 제한할 수 있습니다.</p>
      <p>③ 이용 계약은 동조 1 항의 약관 동의를 포함하는 이용자의 서비스 이용 신청을 팀이 승낙하면 성립합니다.</p>
      <br />
      <h4>제6조 (이용 신청)</h4>
      <p>
        이용 신청은 이용자가 회원이 되어 본 서비스를 이용하기 위해 수행하는 절차로서, 이용자가 본 서비스의 모바일
        어플리케이션을 내려 받은 후 최초 실행 때 제시되는 이용약관에 동의하는 방식으로 이루어집니다. 다만, 본 서비스 중
        일부 서비스는 이용 약관에 동의하지 않을 채로 이용 가능하며 그 경우에는 이용자의 이용이 제한될 수 있습니다.
      </p>
      <br />
      <h4>제7조 (이용 신청의 승낙)</h4>
      <p>① 팀은 전 조의 규정에 따른 유효한 이용 신청이 있으면 이용자의 서비스 이용 신청을 승낙할 수 있습니다.</p>
      <p>
        ② 팀이 이용자의 이용 신청을 승낙하는 동시에 이용자는 팀이 제공하는 본 서비스의 회원이 되어 본 서비스를 모두
        이용할 수 있으며, 본 서비스의 이용에 따른 별도의 비용이 들어가게 되면 팀은 회원에게 이를 알립니다.
      </p>
      <br />
      <h4>제8조 (이용 신청에 대한 승낙의 제한)</h4>
      <p>팀은 다음 각 호에 해당하는 이용자의 이용 신청에 대하여는 승낙을 하지 않을 수 있습니다.</p>
      <ul>
        <li>1. 다른 사람의 명의를 사용하거나 이용자 등록 시 허위로 신청하는 경우</li>
        <li>2. 팀에 의하여 이용계약이 해지된 날로부터 2개월 이내에 재 이용 신청을 하는 경우 </li>
        <li>3. 기술상 서비스 제공이 불가능한 경우</li>
        <li>4. 고객 등록 사항을 누락하거나 오기하여 신청하는 경우</li>
        <li>5. E-mail 승인 또는 SMS 인증 등 팀이 정한 인증절차를 완료하지 않은 경우</li>
        <li>6. 만 14세 미만의 아동인 경우</li>
        <li>
          7. 가입신청자가 회원 자격을 상실한 사실이 있는 경우, 단, 회원 자격을 상실한 날로부터 1년이 경과한 자로서 팀의
          재가입 승낙을 얻은 경우에는 그러하지 아니합니다.
        </li>
        <li>
          8. 팀으로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는
          경우
        </li>
        <li>9. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우</li>
        <li>
          10. 기타 팀이 정한 이용신청 요건이 만족되지 않았거나 위법 또는 부당한 이용신청임이 확인된 경우 또는 회원의
          책임 있는 사유로 인하여 팀이 승낙할 수 없는 경우
        </li>
        <li>11. 이외의 사유로 고의 또는 과실로 팀의 이익을 침해할 수 있다고 팀에서 판단하는 경우</li>
      </ul>
      <br />
      <br />
      <h3>제3장 서비스 이용</h3>
      <h4>제9조 (서비스의 내용)</h4>
      <p>
        ① 팀은 이용자 또는 회원에게 다음 각 호의 서비스를 제공합니다. 단, 이 서비스 목록은 팀의 사정에 의해 언제든지
        변경될 수 있으며 또한 하기 목록에 존재하는 서비스일지라도 이용자에게 제공이 불가할 수 있습니다.
      </p>
      <ul>
        <li>
          1. 회원의 주식 혹은 코인 거래 정보를 문자 메시지 크롤링 혹은 어플리케이션 알림 파싱을 통해 다른 회원에게
          전달하는 서비스
        </li>
        <li>2. 회원이 보유한 연락처를 기반으로 기존 가입한 다른 회원을 검색하는 서비스</li>
        <li>3. 회원이 등록한 닉네임을 기반으로 다른 회원을 검색하는 서비스</li>
        <li>
          4. 회원이 보유한 타 금융 모바일 어플리케이션(증권사 어플리케이션, 코인 거래 어플리케이션)으로의 연동 서비스
        </li>
        <li>5. 회원이 버디 신청하고 구독 완료한 유투버의 주식 혹은 코인 거래 정보를 제공하는 서비스</li>
        <li>6. 회원이 버디 신청한 유투버 계정에 대한 자동 구독 서비스</li>
        <li>7. 30분 이내의 코스피, 코스닥 지수 조회 서비스</li>
        <li>8. 다른 회원을 즐겨찾는 회원으로 설정하는 서비스</li>
        <li>9. 유투버 추천 서비스</li>
        <li>10. 다른 회원의 버디 수를 알려주는 서비스</li>
        <li>11. 친구 관계를 맺은 다른 회원의 금일 거래 내역 수를 알려주는 서비스</li>
        <li>12. 친구 관계를 맺은 다른 회원의 신규 거래 내역 수를 알려주고 해당 거래 내역을 표시하는 서비스</li>
        <li>13. 버디 요청 거절 서비스</li>
        <li>
          14. 기타 팀이 본 서비스의 본질에 부합하며, 회원의 편의성을 향상시킨다고 판단하여 유·무선 인터넷, 모바일
          어플리케이션을 통해 제공하는 일체의 서비스
        </li>
      </ul>
      <p>② 회원은 본 서비스 이용 동의 후 제휴사가 제공하는 서비스에 가입할 수 있습니다.</p>
      <p>
        ③ 회원은 본 서비스에서 제공하는 모든 서비스를 이용할 수 있으며, 일부 서비스의 경우는 별도의 모바일
        어플리케이션을 통해서도 이용할 수 있습니다.
      </p>
      <p>④ 본 서비스 내 개별 서비스별 구체적인 안내사항은 개별 서비스 화면에서 확인할 수 있습니다.</p>
      <br />
      <h4>제 10 조 (서비스의 이용 개시)</h4>
      <p>
        ① 팀은 이용자가 본 서비스의 모바일 어플리케이션을 본인 명의의 단말기에 다운로드 및 설치한 뒤 이용약관에
        동의함으로써 본 서비스의 제공을 개시합니다. 팀이 정하는 일부 서비스의 경우 이용약관 동의 없이 사용할 수 있으나,
        팀의 방침에 따라 제한될 수 있습니다.
      </p>
      <p>
        ② 팀의 업무상 또는 기술상의 장애로 본 서비스의 제공을 개시하지 못할 때에는 해당 내용을 모바일 어플리케이션 내
        혹은 플레이 스토어, 웹 사이트 중 한 곳 이상에 통지합니다.
      </p>
      <p>
        ③ 회원은 본 서비스에서 제공하는 모든 서비스를 이용할 수 있으며, 일부 서비스의 경우는 별도의 모바일
        어플리케이션을 통해서도 이용할 수 있습니다.
      </p>
      <p>④ 본 서비스 내 개별 서비스별 구체적인 안내사항은 개별서비스 화면에서 확인할 수 있습니다.</p>
      <br />
      <h4>제11조 (대리행위 및 보증의 부인)</h4>
      <p>
        ① 팀은 회원과 제휴사 간의 자유로운 서비스의 매개를 위한 시스템을 운영 및 관리, 제공할 뿐이므로 회원 또는
        제휴사를 대리하지 않으며, 회원과 제휴사 또는 회원과 회원 사이에 성립된 계약과 관련된 책임과 회원이 제공한 정보에
        대한 책임은 해당 회원이 직접 부담하여야 합니다.
      </p>
      <p>
        ② 팀은 본 서비스를 통하여 이루어지는 회원과 제휴사 간의 계약과 관련하여 체결 의사의 존재 여부 및 진정성, 계약
        내용 및 조건의 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 회원 또는 제휴사가 입력하는 정보 및 그
        정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 않으며, 이와 관련한 일체의
        위험과 책임은 해당 회원 또는 제휴사가 전적으로 부담합니다.
      </p>
      <p>
        ③ 회원은 상품 등을 구매하기 전에 반드시 그 상세 내용과 거래 조건을 정확하게 확인한 후 구매해야 합니다. 한편,
        구매의 철회, 환불 등 제반 문제는 회원과 제휴사 간의 문제이므로 팀은 이에 관여하지 않습니다. 다만, 팀은 회원과
        제휴사 간의 거래와 관련하여 통신판매중개자로서 `전자상거래 등에서의 소비자보호에 관한 법률’ 등 관련 법령에서
        정한 책임은 부담합니다.
      </p>
      <br />
      <h4>제12조 (서비스의 이용시간)</h4>
      <p>
        ① 본 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 팀의 업무상이나 기술상의 이유로 또는
        이동전화망이나 인터넷망의 불안정이나 기술상의 이유로 본 서비스가 일시 중지될 수 있으며, 운영상의 필요성으로 팀이
        정한 기간에 본 서비스가 일시 중지될 수 있습니다.
      </p>
      <p>
        ② 팀은 본 서비스를 일정 범위로 나누어 범위별로 이용할 수 있는 시간을 별도로 정할 수 있으며 이때 그 내용을
        공지합니다.
      </p>
      <br />
      <h4>제13조 (서비스의 변경 및 중지)</h4>
      <p>
        ① 팀은 본 서비스를 변경하여 제공할 수 있습니다. 이때 팀은 변경될 서비스의 내용과 제공일자를 제19조에서 정한
        방법으로 회원에게 공지하거나 통지합니다.
      </p>
      <p>② 팀은 다음 각 호에 해당하면 본 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
      <ul>
        <li>1. 서비스용 설비의 보수 등 공사로 부득이한 경우 </li>
        <li>2. 회원이 팀의 본 서비스 운영을 방해하는 경우 </li>
        <li>3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 </li>
        <li>4. 본 서비스의 변경 및 중단 등과 같은 특수한 사정으로 본 서비스를 유지할 수 없는 경우</li>
        <li>5. 기타 천재지변, 국가비상사태 등 불가항력 사유가 있는 경우</li>
        <li>6. 기타 팀에서 정한 사유가 있는 경우</li>
      </ul>
      <p>
        ③ 전 항에 따라 본 서비스를 중단할 때에는 팀은 제19조에서 정한 방법으로 회원에게 통지합니다. 다만, 팀이 통제할 수
        없는 사유로 본 서비스를 중단(디스크 장애, 시스템 다운 등)하게 되어 미리 통지할 수 없었을 때에는 그렇지 않습니다.
      </p>
      <p>
        ④ 팀은 고의 또는 과실이 없는 한 본 조에 따른 본 서비스의 변경, 중지로 발생하는 문제에 대해 어떠한 책임도 지지
        않습니다.
      </p>
      <br />
      <h4>제14조 (정보의 제공 및 광고의 게재)</h4>
      <p>
        ① 팀은 본 서비스의 운영과 관련한 공지사항을 본 서비스의 홈페이지 또는 모바일 어플리케이션의 서비스 화면에
        게재하거나 문자메시지(SMS), 이메일(E-mail), 푸시 알람 등으로 통지할 수 있습니다.
      </p>
      <p>
        ② 팀은 본 서비스의 운영과 관련한 각종 정보와 광고를 본 서비스의 홈페이지 또는 모바일 어플리케이션의 서비스
        화면에 게재하거나 회원의 동의를 얻어 이메일(E-mail), 우편, 단문/장문 문자메시지(SMS/MMS), 텔레마케팅(TM) 등의
        방법으로 회원에게 제공할 수 있습니다.
      </p>
      <p>
        ③ 회원이 본 서비스상에 게재된 광고를 이용하거나 본 서비스를 통한 광고주의 판촉활동에 참여하여 광고주와
        교신하거나 거래하는 것은 전적으로 회원과 광고주 간의 문제입니다. 따라서 회원과 광고주 간에 분쟁이 발생하면
        회원과 광고주가 직접 해결해야 하며, 이와 관련하여 팀은 어떠한 책임도 지지 않습니다.
      </p>
      <br />
      <h4>제15조 (게시물 또는 내용물의 게시 등 중단)</h4>
      <p>
        ① 팀은 회원이 게시하거나 전달하는 모든 게시물이 다음 각 호에 해당한다고 판단되면 사전 통지 없이 게시, 전달 등을
        중단할 수 있으며, 이에 대해 팀은 어떠한 책임도 지지 않습니다.
      </p>
      <ul>
        <li>1. 팀, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상하는 경우</li>
        <li>2. 공공질서와 미풍양속을 거스르는 내용의 게시물을 유포하는 경우</li>
        <li>3. 범죄 행위에 결부된다고 인정되는 경우 </li>
        <li>4. 팀 또는 제3자의 저작권 등 기타 권리를 침해하는 경우</li>
        <li>5. 본 조 제2항에 정한 세부이용지침을 통하여 팀에서 규정한 게시 기간을 초과한 경우</li>
        <li>6. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
        <li>7. 기타 관계 법령과 팀의 지침 등을 위반하는 것으로 판단되는 경우</li>
      </ul>
      <p>
        ② 팀은 게시물과 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물을
        등록하거나 삭제하여야 합니다.
      </p>
      <p>
        ③ 팀은 본조 2항의 지침을 위배하는 회원 혹은 팀에게 손해를 끼친다고 판단하는 회원의 이용을 고지 없이 중지할 수
        있으며, 이후 손해 배상을 청구할 수 있습니다. 이러한 이용 중지로 인한 어떠한 손해도 팀은 배상하지 않습니다.
      </p>
      <br />
      <br />
      <h3>제4장 계약당사자의 의무</h3>
      <h4>제17조 (팀의 의무)</h4>
      <p>
        ① 팀은 본 서비스 제공과 관련하여 수집한 회원의 개인정보 또는 개인위치정보(이하 통칭할 경우 ‘개인정보 등’)를 해당
        회원의 동의 없이 제3자에게 제공, 누설, 전송, 배포하지 않습니다. 단, 관계 법령에 따라 권한 있는 자가 적법한
        절차를 통해 개인정보의 제공을 요청할 때에는 그렇지 않습니다.
      </p>
      <p>
        ② 팀은 서비스의 개선, 고객 만족도 향상 등의 목적으로 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보에 관한
        통계자료를 작성하여 이를 사용할 수 있습니다.
      </p>
      <p>
        ③ 팀은 본 서비스와 관련한 회원의 불만사항이 접수되면 이를 신속하게 처리해야 하며, 신속한 처리가 어려울 때에는 그
        사유와 처리 일정을 홈페이지의 공지사항 또는 이동전화망을 통하여 구현된 서비스 화면에 게재하거나 문자메시지(SMS)
        등으로 해당 회원에게 통지합니다.
      </p>
      <p>
        ④ 본 서비스로 말미암아 회원에게 손해가 발생하였을 때 그러한 손해가 팀의 고의나 과실에서 비롯되었다면 팀에서
        책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.
      </p>
      <p>
        ⑤ 본 조 4항의 손해에는 타 회원 혹은 제휴사(유투버 포함)의 금융 거래 정보를 기반으로 회원이 거래하여 입은 손해는
        포함하지 않습니다. (본 서비스로 말미암아 회원에게 손해가 발생하였을 때에도 팀은 법적으로 규정한 손해 이외의
        책임은 지지 않습니다.)
      </p>
      <p>⑥ 팀은 관련 법령 등 본 서비스의 운영, 유지와 관련된 법규를 지킵니다.</p>
      <br />
      <h4>제18조 (회원의 의무)</h4>
      <p>① 회원은 본 서비스를 이용할 때 다음 각 호의 1에 해당하는 행위를 해서는 안 됩니다.</p>
      <ul>
        <li>
          1. 이용 신청 또는 변경 때 거짓된 정보를 적거나 다른 회원의 이동전화번호를 무단으로 부정하게 사용하는 행위
        </li>
        <li>
          2. 본 서비스를 이용하여 얻은 팀의 정보를 팀의 사전 동의 없이 복제 또는 유통하거나 상업적으로 이용하는 행위
        </li>
        <li>3. 팀 또는 제3자의 지적 재산권 등 기타 권리를 침해하는 행위 </li>
        <li>
          4. 본 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발하는 컴퓨터 바이러스 감염 자료를 등록
          또는 유포, 연결(링크)하는 행위
        </li>
        <li>
          5. 본 서비스 운영을 고의로 방해하거나 본 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인
          수신거부 의사를 거부하고 광고성 정보를 전송하는 행위
        </li>
        <li>6. 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위 </li>
        <li>7. 다른 회원의 개인정보를 수집, 저장, 공개하는 행위</li>
        <li>8. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 끼칠 목적으로 거짓된 정보를 유통하는 행위</li>
        <li>9. 기타 불법적이거나 부당한 행위 </li>
      </ul>
      <p>
        ② 본 서비스에 관한 저작권 및 지적재산권 등 모든 권리는 팀에 귀속되며, 회원은 팀으로부터 본 서비스를 이용할 수
        있는 사용권을 획득한 것이므로 회원은 관계 법령, 본 약관 및 위치기반서비스 이용약관의 규정, 이용안내 및 본
        서비스상에 공지한 주의사항, 팀이 통지하는 사항 등을 지켜야 하며, 기타 팀의 업무에 방해되는 행위를 해서는 안
        됩니다.
      </p>
      <p>
        ③ 회원은 팀에서 공식적으로 인정한 경우 이외에는 본 서비스를 이용하여 상품을 판매하는 영업활동을 할 수 없으며,
        특히 해킹, 광고를 통한 수익, 음란 사이트를 통한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에
        관해 팀은 책임을 지지 않으며, 이러한 행위로 팀에 손해가 발생했을 때 회원은 팀에 대하여 손해배상 의무를 집니다.
      </p>
      <p>
        ④ 회원이 본 조 제1항에 명시된 행위를 했을 때 팀은 부가적으로 제공한 혜택의 일부 또는 전부의 회수, 특정 서비스의
        이용제한, 이용계약의 해지, 손해배상의 청구 등의 조치를 할 수 있습니다.
      </p>
      <p>
        ⑤ 본 조 제4항에서 정한 조치를 할 때 팀은 해당 내용을 미리 회원에게 통보하며, 회원과 연락할 수 없거나 긴급을 요할
        때에는 먼저 조치한 후에 통보할 수 있습니다.
      </p>
      <p>
        ⑥ 회원은 본 조 제4항에 따른 팀의 조치에 대해 항변 사유가 있으면, 팀이 인정하는 경우 즉시 본 서비스의 이용을
        재개합니다.
      </p>
      <p>
        ⑦ 본 조 제4항에 따라 팀이 회원과의 이용계약을 해지하더라도 해지 이전에 체결된 매매계약의 완결에 관해서는 본
        약관이 계속 적용됩니다.
      </p>
      <p>⑧ 본 조 제4항에서 정한 바에 따라 이용계약이 종료되면 회원의 재이용 신청을 팀은 승낙하지 않을 수 있습니다.</p>
      <p>
        ⑨ 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가
        부정확하여 이로 인해 팀이 회원에게 원활한 서비스를 제공하지 못하거나 회원이 불이익을 당하게 되는 경우, 팀은 그에
        대해 책임지지 않습니다.
      </p>
      <br />
      <h4>제19조 (회원에 대한 통지)</h4>
      <p>
        ① 회원에게 통지할 때 팀은 해당 내용을 홈페이지의 공지사항 또는 모바일 어플리케이션의 서비스 화면에 게재하거나
        문자메시지(SMS), 전자우편(E-Mail) 등으로 회원에게 전달할 수 있습니다.
      </p>
      <p>
        ② 팀이 불특정 다수 회원에게 통지할 때는 홈페이지 또는 모바일 어플리케이션의 서비스 화면에 7일 이상 게시하는
        것으로 개별 통지를 대신할 수 있습니다.
      </p>
      <br />
      <h4>제20조 (이용자의 개인정보 등 보호)</h4>
      <p>
        팀은 관련 법령이 정하는 바에 따라 회원 등록정보를 포함한 회원의 개인정보 등을 보호하기 위하여 노력합니다. 회원의
        개인정보 등에 대한 보호와 관련된 사항은 관련 법령 및 팀이 정하는 &apos;개인정보처리방침&apos;에 정한 바를
        따릅니다.
      </p>
      <br />
      <h4>제21조 (개인정보의 제공, 위탁)</h4>
      <p>
        ① 팀은 본 서비스의 제공을 위하여 관련 법령에 따라 회원의 개인정보 등을 제휴사, 가맹점 또는 위탁사 등에 제공할 수
        있습니다.
      </p>
      <p>
        ② 팀은 수집된 개인정보의 취급 및 관리 업무(이하 &apos;업무&apos;)를 스스로 수행함을 원칙으로 하나, 필요하면
        업무의 일부 또는 전부를 팀이 선정한 팀에 위탁할 수 있습니다.
      </p>
      <p>
        ③ 팀은 회원의 개인정보 등을 본 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게
        제공하지 않습니다. 단, 다음의 경우는 예외로 합니다.
      </p>
      <ul>
        <li>1. 배송에 필요한 최소한의 회원의 개인정보(성명, 주소, 전화번호)를 배송업체에 알려주는 경우</li>
        <li>2. 통계작성, 학술연구, 시장조사에 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우 </li>
        <li>3. 상품 등의 거래에 따른 대금정산을 위하여 필요한 경우 </li>
        <li>4. 법령이 회원의 개인정보 등의 이용과 제3자에 대한 정보제공을 허용하는 경우</li>
      </ul>
      <br />
      <br />
      <h3>제5장 계약해지 및 이용제한</h3>
      <h4>제22조 (계약해지 및 이용제한)</h4>
      <p>
        ① 회원은 언제든지 팀에 이용계약 해지의 의사를 통지하여 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기
        전에 현재 진행 중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야 하며, 거래를 철회하거나 취소했을 때
        발생할 수 있는 불이익은 회원 본인이 부담하여야 합니다.
      </p>
      <p>
        ② 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이 종료되면 팀은 회원에게
        부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
      </p>
      <p>
        ③ 팀은 회원이 임의 해지, 재가입 등을 반복적으로 행하여 팀이 제공하는 할인쿠폰, 이벤트 혜택 등의 경제적 이익을
        취하거나 이 과정에서 타인의 명의를 무단으로 사용하는 등 불법과 편법 행위를 하는 회원을 차단하기 위하여 회원의
        회원탈퇴 후3개월 동안 해당 회원의 아이디(ID), 성명, 본인확인기관을 통해 받는 연계정보(CI), 중복가입
        식별정보(DI), 탈퇴일자, 탈퇴사유 등 개인정보를 보관합니다.
      </p>
      <p>
        ④ 팀은 회원이 제18조에 규정한 회원의 의무를 이행하지 않으면 사전 통지 없이 즉시 이용계약을 해지하거나 본
        서비스의 제공을 중지할 수 있습니다.
      </p>
      <p>
        ⑤ 팀은 회원 가입 후 1년 동안 본 서비스를 사용한 적이 없는 회원에게 서비스를 계속 사용할지를 묻고, 팀이 정한 기한
        내에 그에 대한 답변이 없으면 관계 법령에 따라 조치를 취할 수 있습니다.
      </p>
      <p>⑥ 본 조 제4항과 제5항에 규정된 팀의 조치에 대해 회원은 팀이 정한 절차에 따라 이의신청을 할 수 있습니다. </p>
      <p>⑦ 전 항의 이의가 정당하다고 팀이 인정하면, 팀은 즉시 본 서비스의 이용을 재개합니다.</p>
      <br />
      <h4>제23조 (양도 금지)</h4>
      <p>
        회원은 본 서비스의 이용권한 등 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 담보로 제공할 수 없습니다.
      </p>
      <br />
      <br />
      <h3>제6장 손해배상 등</h3>
      <h4>제24조 (손해배상)</h4>
      <p>① 본 약관의 규정을 위반하여 팀에 손해를 끼친 회원은 팀에 발생한 모든 손해를 배상해야 합니다.</p>
      <p>
        ② 회원이 본 서비스를 이용하면서 불법 행위나 본 약관을 위반하는 행위를 하여 팀이 해당 회원 이외의 제3자로부터
        손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 회원은 자신의 책임과 비용으로 팀을 면책시켜야
        하며, 팀이 면책되지 못하면 팀에 발생한 모든 손해를 배상해야 합니다.
      </p>
      <br />
      <h4>제25조 (면책사항)</h4>
      <p>
        ① 천재지변 또는 이에 준하는 불가항력으로 본 서비스를 제공할 수 없을 때 팀은 본 서비스 제공에 관한 책임이
        면제됩니다.
      </p>
      <p>② 팀은 본 서비스의 이용장애에 대해 책임을 지지 않습니다. </p>
      <p>
        ③ 팀은 회원이 본 서비스를 이용하여 기대하는 수익을 달성하지 못했거나 상실한 것에 대해 책임을 지지 않으며 그 밖에
        본 서비스를 통하여 얻은 정보로 인하여 발생한 손해 등에 대해서도 책임을 지지 않습니다. 팀은 회원이 사이트에
        게재한 정보, 자료, 사실의 신뢰도 및 정확성 등에 대해 책임을 지지 않습니다.
      </p>
      <p>
        ④ 팀은 본 서비스를 매개로 회원 상호 간 또는 회원과 제3자 상호 간에 분쟁이 발생하였을 때 이에 개입할 의무가
        없으며 분쟁에 따른 손해를 배상할 책임도 없습니다.
      </p>
      <p>
        ⑤ 회원이 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대해 팀은 일절 책임을 지지 않습니다.
      </p>
      <p>
        ⑥ 제휴사 또는 회원이 상품 등이나 게시물을 통해 표현하거나 드러내는 제휴사 또는 회원의 견해와 팀의 견해는
        무관하며, 팀은 제휴사 또는 회원이 제공하는 상품 등이나 게시물의 정보 등에 대해 어떠한 책임도 부담하지 않습니다.
      </p>
      <p>
        ⑦ 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 팀(이하 ‘피연결팀’)와 회원 간에 이루어진 거래에 팀은 개입하지
        않으며, 해당 거래에 대해 책임을 지지 않습니다.
      </p>
      <br />
      <h4>제26조 (관할법원)</h4>
      <p>
        ① 본 서비스 이용과 관련하여 팀과 회원 사이에 분쟁이 발생하면, 팀과 회원은 분쟁의 해결을 위해 성실히 협의합니다.
      </p>
      <p>
        ② 전 항의 협의에도 불구하고 분쟁이 해결되지 않으면, 팀과 회원 양 당사자는 민사소송법상의 관할법원에 소를 제기할
        수 있습니다.
      </p>
      <br />
      <h4>부칙</h4>
      <p>(시행일) 본 약관은 2023년 01월 08일부터 시행합니다. </p>
    </div>
  );
};

export default Terms;
