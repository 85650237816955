import React from 'react';
import '../../../index.css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { bottomImageStyle, topImageStyle } from '../../../style/style';
import { MobileFirstTopImg, MobileFirstBottomImg } from '../../../static/images';

const MobileVerSectionFirst = () => (
  <MobileDefaultTemplate
    backgroundColor={'#2AF5C6'}
    text={'세상에 없던 지인 기반 금융 SNS, 버디스탁'}
    description={
      <span>
        주식과 코인으로 재테크 하는 세상, <br />
        가까운 내 지인들은 무엇을 거래하고 있을까요? 버디스탁은 소통하는 금융 문화를 지향합니다.
      </span>
    }
    desWidth={268}
    imgContainerStyle={{ width: '258px', margin: '52px 0 46px' }}
  >
    <div className={topImageStyle({ top: '-8%', right: '-13%' })}>
      <img src={MobileFirstTopImg} alt={'buddystock_top_img'} />
    </div>
    <div className={bottomImageStyle({ top: '0%', left: '-1%' })}>
      <img src={MobileFirstBottomImg} alt={'buddystock_bottom_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFirst;
