import React, { ReactNode } from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { BuddystockChartIcon, BuddystockUpIcon, PcThirdTopImg } from '../../../static/images';
import {
  containerStyle,
  contentStyle,
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  rootStyle,
  topImageStyle
} from '../../../style/style';

const iconComponentDivStyle = css`
  display: flex;
  align-items: center;
  word-break: keep-all;
`;

const iconTitleStyle = css`
  margin: 16px 0px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #3d3d3d;
`;

const iconContentStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
`;

const IconComponent = ({
  icon,
  title,
  content,
  isUpIcon
}: {
  icon: string;
  title: string;
  content: string;
  isUpIcon: boolean;
}) => (
  <div className={css({ display: 'grid', marginRight: isUpIcon ? '68px' : '0px' })}>
    <div>
      <img src={icon} alt={'buddystock_icon'} />
    </div>
    <div className={iconTitleStyle}>{title}</div>
    <div className={iconContentStyle}>{content}</div>
  </div>
);

const PcVerSectionThird = () => (
  <div className={rootStyle('#F5F5F5')}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '190px 86px' })}>
        <div className={imageBoxStyle({ width: '248px' })}>
          <div className={topImageStyle({ top: '-5%', left: '-11.5%' })}>
            <img src={PcThirdTopImg} alt={'buddystock_top_img'} />
          </div>
        </div>
        <div className={css({ width: '420px', marginTop: '40px' })}>
          <div className={mainTextStyle({ fontSize: '40px' })}>유튜버의 매매 정보를 실시간으로</div>
          <div className={descriptionTextStyle({ marginBottom: '102px' })}>
            내가 구독한 유튜버는 지금 뭘 샀을까? 진짜 잘 하는 유튜버일까? 버디스탁을 통해 실시간으로 받아보세요.
          </div>
          <div className={iconComponentDivStyle}>
            <IconComponent
              icon={BuddystockUpIcon}
              title={'추천 유튜버디'}
              content={'버디스탁에서 제공하는 추천 유튜버를 만나보세요'}
              isUpIcon={true}
            />
            <IconComponent
              icon={BuddystockChartIcon}
              title={'유튜버디 매매정보'}
              content={'유튜버디의 실시간 매매 정보를 버디스탁을 통해 확인해보세요'}
              isUpIcon={false}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionThird;
