import React from 'react';
import '../../../index.css';
import { topImageStyle } from '../../../style/style';
import { MobileFifthTopImg } from '../../../static/images';
import MobileDefaultTemplate from '../MobileDefaultTemplate';

const MobileVerSectionFifth = () => (
  <MobileDefaultTemplate
    text={'내 주위 숨은 고수들과 나누는 실시간 주식˙코인 소통의 장'}
    textStyle={{
      textAlign: 'center',
      fontSize: '20px',
      width: '240px',
      lineHeight: '28px'
    }}
    imgContainerStyle={{
      width: '325px',
      height: '400px',
      margin: '28px auto 0',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden'
    }}
  >
    <div className={topImageStyle({ left: '11%' })}>
      <img src={MobileFifthTopImg} alt={'buddystock_top_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFifth;
