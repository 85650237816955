import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { PcSecondBottomImg, PcSecondMiddleImg, PcSecondTopImg } from '../../../static/images';
import {
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  bottomImageStyle,
  contentStyle,
  containerStyle,
  rootStyle,
  topImageStyle,
  middleImageStyle
} from '../../../style/style';

const PcVerSectionSecond = () => (
  <div className={rootStyle('')}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '165px 0px 115px' })}>
        <div className={css({ width: '338px', marginTop: '80px' })}>
          <div className={mainTextStyle({ fontSize: '40px' })}>친구들과 나누는 실시간 매매 정보</div>
          <div className={descriptionTextStyle('')}>
            연락처는 물론, 닉네임으로도 친구를 찾을 수 있어요. <br />
            친한 친구들의 매매 정보를 실시간으로 받아보세요.
          </div>
        </div>
        <div className={imageBoxStyle({ width: '428px', marginRight: '114px' })}>
          <div className={topImageStyle({ top: '-11%', right: '15%' })}>
            <img src={PcSecondTopImg} alt={'buddystock_top_img'} />
          </div>
          <div className={middleImageStyle({ bottom: '-12.5%', right: '-3%' })}>
            <img src={PcSecondMiddleImg} alt={'buddystock_middle_img'} />
          </div>
          <div className={bottomImageStyle({ bottom: '-0.5%', right: '-38%' })}>
            <img src={PcSecondBottomImg} alt={'buddystock_bottom_img'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionSecond;
