import React, { ReactNode } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { OpenMail } from '../OpenMail';
import { INQUIRY_ITEMS, TERM_ITEMS } from './constants';

const rootStyle = css`
  padding: 42px 0px;
  background-color: #242424;
`;

const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 952px;
  margin: 0 auto;
`;

const inquiryStyle = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const termsStyle = css`
  grid-column: 2;
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
`;

const footerItemsDivStyle = css`
  display: flex;
`;

const footerItemStyle = css`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.2px;
  &:not(:last-of-type) {
    margin-right: 32px;
  }
  color: #dedede;
`;

const copyrightTextStyle = css`
  margin-top: 20px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #666666;
`;

interface IItem {
  label: ReactNode;
  event?: () => void;
}

const FooterItemComponent = ({ items }: { items: IItem[] }) => {
  return (
    <div className={footerItemsDivStyle}>
      {items.map((item: IItem, index: number) => {
        return (
          <div key={index} className={footerItemStyle} onClick={item.event}>
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

const PcVerFooter = () => {
  return (
    <div className={rootStyle}>
      <div className={containerStyle}>
        <div className={inquiryStyle}>
          <FooterItemComponent items={INQUIRY_ITEMS} />
        </div>
        <div className={termsStyle}>
          <FooterItemComponent items={TERM_ITEMS} />
        </div>
      </div>
      <div className={containerStyle}>
        <div className={copyrightTextStyle}>ⓒ Pickstudio. All rights reserved.</div>
      </div>
    </div>
  );
};

export default PcVerFooter;
