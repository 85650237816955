import React, { ReactNode } from 'react';
import '../index.css';
import { css } from '@emotion/css';

const buttonStyle = ({
  color,
  width,
  isMobile
}: {
  color: 'black' | 'white' | 'gray';
  width: number;
  isMobile: boolean;
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width}px;
  height: 56px;
  background: ${(color === 'black' && '#242424') ||
  (color === 'gray' && '#EBEBEB') ||
  (color === 'white' && '#ffffff')};
  color: ${(color === 'black' && '#ffffff') || (color === 'gray' && '#242424') || (color === 'white' && '')};
  border-radius: 8px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: ${isMobile ? '' : '16px'};
    margin-bottom: ${isMobile ? '16px' : ''};
  }
`;

const buttonTextStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

interface IIconButton {
  label: string;
  icon: ReactNode;
  color: 'black' | 'white' | 'gray';
  width: number;
  onClick: () => void;
  isMobile?: boolean;
}

const IconButton = ({ label, icon, color, width, onClick, isMobile = false }: IIconButton) => (
  <div className={buttonStyle({ color, width, isMobile })} onClick={onClick}>
    {icon}
    <div className={buttonTextStyle}>{label}</div>
  </div>
);

export default IconButton;
