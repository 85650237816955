import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import {
  BuddystockAppleStoreIcon,
  BuddystockGooglePlayIcon,
  MobileBottomSheetBuddystockIcon
} from '../../static/images';
import IconButton from '../IconButton';
import { APP_STORE } from '../../constants';

const commonRootStyle = `
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 320px;
  z-index: 40;
`;

const navStyle = (hiddenFooterNav: boolean) => css`
  ${commonRootStyle}
  display: ${hiddenFooterNav ? 'none' : 'block'};
  background-color: #242424;
  cursor: pointer;
`;

const bottomSheetStyle = (openBottomSheet: boolean) => css`
  ${commonRootStyle}
  display: ${openBottomSheet ? 'block' : 'none'};
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
`;

const containerStyle = (other: any) => css`
  display: grid;
  width: 92%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  ${other};
`;

const centerContainerStyle = css`
  display: grid;
  justify-content: center;
  margin-bottom: 20px;
`;

const textStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #ffffff;
`;

const bottomSheetCloseStyle = css`
  position: absolute;
  top: 20px;
  right: 20px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #a6a6a6;
  cursor: pointer;
`;

const MobileVerFooterNav = ({
  openBottomSheet,
  setOpenBottomSheet
}: {
  openBottomSheet: boolean;
  setOpenBottomSheet: Dispatch<SetStateAction<boolean>>;
}) => {
  const [hiddenFooterNav, setHiddenFooterNav] = useState(false);
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 2300) {
        setHiddenFooterNav(true);
      } else {
        setHiddenFooterNav(false);
      }
    });
  }, []);
  const handleClickFooterNav = () => {
    setHiddenFooterNav(true);
    setOpenBottomSheet(true);
  };

  const handleClickBottomSheetClosed = () => {
    setHiddenFooterNav(false);
    setOpenBottomSheet(false);
  };

  useEffect(() => {
    if (openBottomSheet) {
      const handleClickBottomSheetOutside = (e: any) => {
        const bottomSheet = document.getElementById('bottomSheet');
        if (bottomSheet && !bottomSheet.contains(e.target)) {
          setHiddenFooterNav(false);
          setOpenBottomSheet(false);
        }
      };

      document.addEventListener('click', (e: any) => handleClickBottomSheetOutside(e));
      return document.removeEventListener('click', (e: any) => handleClickBottomSheetOutside(e));
    }
  }, []);

  return (
    <>
      <div className={navStyle(hiddenFooterNav)} onClick={handleClickFooterNav}>
        <div className={containerStyle({ padding: '17px 0px' })}>
          <div className={textStyle}>앱 다운로드</div>
        </div>
      </div>

      <div id={'bottomSheet'} className={bottomSheetStyle(openBottomSheet)}>
        <div className={containerStyle({ padding: '26px 0 32px' })}>
          <div className={bottomSheetCloseStyle} onClick={handleClickBottomSheetClosed}>
            닫기
          </div>
          <div className={centerContainerStyle}>
            <img src={MobileBottomSheetBuddystockIcon} alt={'buddystock_sixth_top'} />
          </div>
          <IconButton
            isMobile
            label={'Google Play'}
            icon={<BuddystockGooglePlayIcon />}
            color={'gray'}
            width={320}
            onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
          />
          <IconButton
            isMobile
            label={'Apple Store'}
            icon={<BuddystockAppleStoreIcon />}
            color={'gray'}
            width={320}
            onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
          />
        </div>
      </div>
    </>
  );
};

export default MobileVerFooterNav;
