import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { PcFourthBottomImg, PcFourthTopImg } from '../../../static/images';
import {
  bottomImageStyle,
  descriptionTextStyle,
  containerStyle,
  imageBoxStyle,
  mainTextStyle,
  topImageStyle,
  rootStyle,
  contentStyle
} from '../../../style/style';

const PcVerSectionFourth = () => (
  <div className={rootStyle('#B8FFFB')}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '190px 0px', alignItems: 'center' })}>
        <div className={css({ width: '414px' })}>
          <div className={mainTextStyle({ fontSize: '50px', marginBottom: '64px' })}>
            오직 내 친구에게만 보여주는 매매 내역
          </div>
          <div className={descriptionTextStyle({ marginBottom: '30px' })}>
            걱정 마세요. 거래량은 버디에게도 공개되지 않아요! <br />
            오직 거래 종목만 공개됩니다.
          </div>
        </div>
        <div className={imageBoxStyle({ width: '414px' })}>
          <div className={topImageStyle({ top: '-5%', left: '-7%' })}>
            <img src={PcFourthTopImg} alt={'buddystock_top_img'} />
          </div>
          <div className={bottomImageStyle({ top: '20px', right: '-7%' })}>
            <img src={PcFourthBottomImg} alt={'buddystock_bottom_img'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionFourth;
